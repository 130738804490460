const form = document.querySelector(".c-home__form");
const formInput = document.querySelector(".c-home__form input");
const resParagraph = document.querySelector(".response-message");
const formButton = document.querySelector(".c-home__form button");

const successMessage = "You have succesfully joined the waitlist!";
const errorMessage =
  "An error occured while sending your request. Please try again later.";

form.addEventListener("submit", async (e) => {
  e.preventDefault();
  const email = formInput.value;

  try {
    formButton.disabled = true;
    const response = await fetch(
      "https://v1.nocodeapi.com/seyi/google_sheets/YhYjKjgeopnWEnSM?tabId=Waitlist",
      {
        method: "post",
        body: JSON.stringify([[email]]),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    formInput.value = "";
    formInput.disabled = true;
    resParagraph.innerHTML = successMessage;
    resParagraph.classList.add("visible");
    formButton.innerHTML = "Success!";
  } catch (error) {
    resParagraph.innerHTML = errorMessage;
    resParagraph.classList.add("visible");
    formButton.disabled = false;
  } finally {
  }
});
